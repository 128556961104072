import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";

// seo
import Seo from "../../components/Seo";

// actions
import {
  doValidateDoctorTwinId,
  doSaveDoctorDetails,
} from "../../actions/doctorReferralActions";

// utils
import localStorage from "../../utils/LocalStorageService";

// components
import HeaderWithLogo from "../../components/Header/HeaderWithLogo";
import DoctorPortal from "../../components/DoctorLandingPage/DoctorPortal/DoctorPortal";
import SuccessToast from "../../components/Toasts/SuccessToast";

const DoctorPortalPage = () => {
  const [doctorId, setDoctorId] = useState(0);
  const [doctorDetails, setDoctorDetails] = useState("");
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [serverError, setServerError] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  // If access token not present in Local storage
  // Navigate the user to doctor lp login page
  useEffect(() => {
    const accessToken = localStorage.getDxAccessToken();
    if (!accessToken) {
      navigate("/doctor/login");
    }
  }, []);

  // Function ->> Restrict(logout) user to move back and forth when user clicks the browser back button
  useEffect(() => {
    window.addEventListener("popstate", handleLogout);

    return () => {
      window.removeEventListener("popstate", handleLogout);
    };
  }, []);

  // Function ->> To Logout The User
  const handleLogout = () => {
    localStorage.clearToken();
    navigate("/doctor/login"); // redirect to doctor lp login page
  };

  const fetchDoctorDetails = async data => {
    try {
      setShowLoader(true);
      setServerError("");
      setDoctorId(data.id);
      const response = await doValidateDoctorTwinId(Number(data.id));
      setDoctorDetails(response.data);
      setShowLoader(false);
    } catch (error) {
      console.log(
        "Error while fetching doctor details based on Twin Id",
        error
      );
      setServerError(
        "Invalid doctor id. This doctor id does not exist in our system."
      );
      setDoctorDetails("");
      setShowLoader(false);
    }
  };

  const handleDoctorDetailsSave = async data => {
    const genderArray = data.gender.map(obj => {
      return `${obj.value}`;
    });
    const specialisationArr = data.doctorSpecialisation
      .map(obj => {
        return `${obj.name}`;
      })
      .join(",");

    try {
      let doctorBody = {
        indiaClinicId: Number(doctorId),
        name: data.name,
        doctorPhoneNumber: data.doctorMobileNumber,
        email: data.email,
        medicalRegistartionNumber: data.medRegistrationNumber,
        profilePhoto: data.doctorProfilePhoto,
        gender: genderArray[0],
        address: data.address,
        city: data.city,
        state: data.state,
        pincode: data.pincode,
        language: "English",
        designation: data.doctorDesignation,
        experience: data.doctorExperience,
        doctorSpecialization: specialisationArr,
        description: data.doctorDescription,
        doctorHospitalName: data.doctorHospitalName,
        doctorLandingPageEnabled: true,
        doctorAssistantName: data.doctorAssistantName,
        doctorAssistantNumber: Number(data.doctorAssistantNumber),
      };

      const response = await doSaveDoctorDetails(doctorBody);
      // console.log("Response..", response);
      if (response.data) {
        // To hide the form section
        setDoctorDetails("");
        setShowSuccessToast(true);
      }
    } catch (error) {
      console.error("Error in doctor lp details saving api", error);
      setServerError("This doctor details already exists in our system.");
    }
  };

  return (
    <div className="bgDarkBlue" style={{ minHeight: "100vh" }}>
      <Seo
        title="Reverse Diabetes @ Twin | Book an appointment with India's leading Doctors"
        description="Is Diabetes Reversal possible? Book a Personalised Video Consultation with Top Diabetologists & get all your Diabetes-related queries answered."
      />
      <HeaderWithLogo logoPosition="center" />
      <DoctorPortal
        fetchDoctorDetails={fetchDoctorDetails}
        handleDoctorDetailsSave={handleDoctorDetailsSave}
        doctorDetails={doctorDetails}
        serverError={serverError}
        showLoader={showLoader}
      />

      {/* Success Toast */}
      <div>
        <SuccessToast
          position="middle-center"
          showSuccessToast={showSuccessToast}
          delay={5000}
          autohide={true}
          text="Doctor Details saved successfully."
          onClose={() => setShowSuccessToast(false)}
        />
      </div>
    </div>
  );
};

export default DoctorPortalPage;
