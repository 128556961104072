import React, { useState, useRef, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";

// constant
import constants from "../../../constants";

// component
import DropDown from "../../DropDown/DropDown";

import * as styles from "./DoctorPortal.module.css";

const DoctorPortal = props => {
  const [doctorSpecialisation, setDoctorSpecialisation] = useState([]);
  const [doctorGender, setDoctorGender] = useState("");
  const [showDoctorSpecialisationError, setShowDoctorSpecialisationError] =
    useState("none"); // show error for doctor specialisation selection if left empty

  const doctorIdRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    if (props.doctorDetails) {
      setValue("name", props.doctorDetails.name || "");
      setValue("email", props.doctorDetails.email || "");
      setValue("doctorMobileNumber", props.doctorDetails.mobileNumber || "");
      setValue("medRegistrationNumber", props.doctorDetails.registrationNumber);
    }
  }, [props.doctorDetails, setValue]);

  // handle doctor specialisation section
  const handleDoctorSpecialisationSelect = value => {
    if (value.length > 0) {
      setShowDoctorSpecialisationError("none");
      setDoctorSpecialisation(value);
    } else {
      setShowDoctorSpecialisationError("block");
    }
  };

  const handleDoctorGender = value => {
    if (value.length > 0) {
      setDoctorGender(value);
    }
  };

  // Doctor id input box only accepts integers
  const handleDoctorInputBox = e => {
    let inputValue = e.target.value;
    // Use a regular expression to check if the input contains only numbers and accepts only 3 digits
    if (!/^\d*$/.test(inputValue)) {
      // If the input contains non-numeric characters, clear the input
      e.target.value = "";
    }
  };

  // To get the doctor details based on twin id
  const onDoctorIdSubmit = e => {
    e.preventDefault();
    props.fetchDoctorDetails({ id: doctorIdRef.current.value });
  };

  const onSubmit = async data => {
    data.doctorSpecialisation = doctorSpecialisation;
    data.gender = doctorGender;
    props.handleDoctorDetailsSave(data);
    // To show error message for doctor specialisation
    // if(doctorSpecialisation.length === 0) setShowDoctorSpecialisationError("block");
  };

  return (
    <div className={`container twinContainer ${styles.docPortal__cont}`}>
      <div className="row">
        <div className="col-12">
          <form onSubmit={onDoctorIdSubmit}>
            <div className="row">
              <div
                className={`col-12 text-center ${styles.docPortal__contHdng}`}
              >
                <h3>Doctor Landing Page Portal</h3>
              </div>
              <div
                className={`col-12 text-center ${styles.docPortal__docInpSec}`}
              >
                <input
                  type="text"
                  inputMode="numeric"
                  placeholder="Twin Doctor Id*"
                  ref={doctorIdRef}
                  onChange={handleDoctorInputBox}
                />
                <button type="submit">Submit</button>
                <button
                  onClick={() => {
                    navigate("/doctor/doctor-detail-page");
                  }}
                  type="submit"
                >
                  Doctor List Page
                </button>
              </div>
            </div>
          </form>
          {props.serverError ? (
            <div>
              <p className="mt-4 text-danger text-center">
                {props.serverError}
              </p>
            </div>
          ) : null}

          {/* Show Loader */}
          {props.showLoader ? (
            <div className="row">
              <div className="col-12 mt-3 text-center">
                <lottie-player
                  src="https://assets8.lottiefiles.com/packages/lf20_mkzxw4th.json"
                  background="transparent"
                  speed="1"
                  style={{
                    width: "200px",
                    height: "200px",
                    margin: "auto",
                  }}
                  loop
                  // controls
                  autoplay
                ></lottie-player>

                <p className={styles.docPortal__loaderPara}>
                  We are loading doctor details<br></br>
                  for you...
                </p>
              </div>
            </div>
          ) : null}

          {/* Doctor Details */}
          {props.doctorDetails ? (
            <div className={`row`}>
              <div className={`col-12 ${styles.docPortal__docDetailSec}`}>
                <h3>Doctor Details</h3>
                <p>
                  Please enter the details carefully. Once it gets submitted
                  then it will be <span>Non-Editable.</span>
                </p>
                <form
                  name="doctor_details_form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className={`row`}>
                    <div
                      className={`col-6 ${styles.docPortal__docDetailSec__form}`}
                    >
                      <label htmlFor="name">Doctor Name*</label>
                      <input
                        placeholder="Doctor Name*"
                        className={
                          errors.name
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        id="name"
                        type="text"
                        {...register("name", {
                          required: "Please enter doctor name",
                          pattern: {
                            value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                            message: "Please enter valid doctor name",
                          },
                          maxLength: {
                            value: 50,
                            message: "Doctor name is too long",
                          },
                        })}
                        readOnly
                      />
                      {errors.name && (
                        <span className="invalid-feedback">
                          {errors.name.message}
                        </span>
                      )}
                    </div>
                    <div
                      className={`col-6 ${styles.docPortal__docDetailSec__form}`}
                    >
                      <label htmlFor="registrationNumber">
                        Doctor Medical Registration Number*
                      </label>
                      <input
                        placeholder="Doctor Medical Reg No*"
                        className={`form-control letterSp1 ${
                          errors.medRegistrationNumber ? "is-invalid" : ""
                        }`}
                        id="medRegistrationNumber"
                        type="text"
                        {...register("medRegistrationNumber", {
                          required:
                            "Please enter doctor medical registration number",
                        })}
                        readOnly
                      />
                      {errors.medRegistrationNumber && (
                        <span className="invalid-feedback">
                          {errors.medRegistrationNumber.message}
                        </span>
                      )}
                    </div>
                    <div
                      className={`col-6 ${styles.docPortal__docDetailSec__form}`}
                    >
                      <label htmlFor="email">Doctor Email Id*</label>
                      <input
                        type="text"
                        placeholder="Email Id*"
                        className={
                          errors.email
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        id="email"
                        {...register("email", {
                          required: "Please enter doctor email id",
                          pattern: {
                            value:
                              /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                            message: "Please enter valid email id",
                          },
                        })}
                        readOnly
                      ></input>
                      {errors.email && (
                        <span className="invalid-feedback">
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                    <div
                      className={`col-6 ${styles.docPortal__docDetailSec__form}`}
                    >
                      <label htmlFor="mobileNumber">
                        Doctor Mobile Number*
                      </label>
                      <input
                        className={
                          errors.doctorMobileNumber
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Doctor Mobile number"
                        id="doctorMobileNumber"
                        type="text"
                        inputMode="numeric"
                        maxLength="10"
                        {...register("doctorMobileNumber", {
                          required: "Please enter doctor mobile number",
                          pattern: {
                            value:
                              /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/, // eslint-disable-line no-useless-escape
                            message: "Please enter valid mobile number",
                          },
                          maxLength: {
                            value: 10,
                            message: "Invalid mobile number",
                          },
                        })}
                        readOnly
                      />
                      {errors.doctorMobileNumber && (
                        <span className="invalid-feedback">
                          {errors.doctorMobileNumber.message}
                        </span>
                      )}
                    </div>
                    <div className="col-12 mb-5">
                      <div className={styles.docPortal__docDetailSec__dropDown}>
                        <label htmlFor="doctorSpecialisation">
                          Doctor Specialisation*
                        </label>
                        <DropDown
                          displayValue="display"
                          onSelect={handleDoctorSpecialisationSelect}
                          onRemove={handleDoctorSpecialisationSelect}
                          options={constants.DOCTOR_SPECIALISATION}
                          showCheckbox={true}
                          selectionLimit={
                            constants.DOCTOR_SPECIALISATION.length
                          }
                          placeholder="Please select doctor specialisation"
                          hidePlaceholder={true}
                          showArrow={true}
                          customCloseIcon={
                            <StaticImage
                              src="../../../images/bookPack/cross_2.png"
                              alt="cross_icon"
                              className="img-fluid"
                              placeholder="blurred"
                              quality={90}
                              style={{
                                width: "14px",
                                height: "14px",
                                marginLeft: "4px",
                              }}
                            />
                          }
                          customArrow={
                            <StaticImage
                              src="../../../images/bookPack/arrow_dropdown.png"
                              alt="cross_icon"
                              className="img-fluid"
                              placeholder="blurred"
                              quality={90}
                              style={{
                                marginTop: "9px",
                              }}
                            />
                          }
                        />
                        {/* To show doctor specialisation error */}
                        {doctorSpecialisation.length === 0 && (
                          <span
                            className="text-danger"
                            style={{
                              fontSize: "0.875em",
                              display: showDoctorSpecialisationError,
                            }}
                          >
                            <p style={{ marginTop: "22px" }}>
                              Please select doctor specialisation
                            </p>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-6 mb-5">
                      <div className={styles.docPortal__docDetailSec__dropDown}>
                        <label htmlFor="gender">Doctor Gender*</label>
                        <DropDown
                          displayValue="key"
                          onSelect={handleDoctorGender}
                          onRemove={handleDoctorGender}
                          options={constants.GENDERS}
                          showCheckbox={true}
                          selectionLimit={1}
                          placeholder="Please select doctor gender"
                          hidePlaceholder={true}
                          showArrow={true}
                          customCloseIcon={
                            <StaticImage
                              src="../../../images/bookPack/cross_2.png"
                              alt="cross_icon"
                              className="img-fluid"
                              placeholder="blurred"
                              quality={90}
                              style={{
                                width: "14px",
                                height: "14px",
                                marginLeft: "4px",
                              }}
                            />
                          }
                          customArrow={
                            <StaticImage
                              src="../../../images/bookPack/arrow_dropdown.png"
                              alt="cross_icon"
                              className="img-fluid"
                              placeholder="blurred"
                              quality={90}
                              style={{
                                marginTop: "9px",
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div
                      className={`col-6 ${styles.docPortal__docDetailSec__form}`}
                    >
                      <label htmlFor="doctor">Doctor Designation*</label>
                      <input
                        placeholder="Doctor Designation"
                        id="doctorDesignation"
                        className={
                          errors.doctorDesignation
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        type="text"
                        {...register("doctorDesignation", {
                          required: "Please enter doctor designation",
                          pattern: {
                            value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                            message: "Please enter valid doctor designation",
                          },
                          maxLength: {
                            value: 100,
                            message: "Doctor designation is too long",
                          },
                        })}
                      />
                      {errors.doctorDesignation && (
                        <span className="invalid-feedback">
                          {errors.doctorDesignation.message}
                        </span>
                      )}
                    </div>
                    <div
                      className={`col-6 ${styles.docPortal__docDetailSec__form}`}
                    >
                      <label htmlFor="doctor">Doctor Hospital Name</label>
                      <input
                        placeholder="Doctor Hospital Name"
                        id="doctorDesignation"
                        className={
                          errors.doctorHospitalName
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        type="text"
                        {...register("doctorHospitalName")}
                      />
                      {errors.doctorHospitalName && (
                        <span className="invalid-feedback">
                          {errors.doctorHospitalName.message}
                        </span>
                      )}
                    </div>
                    <div
                      className={`col-6 ${styles.docPortal__docDetailSec__form}`}
                    >
                      <label htmlFor="doctor">
                        Doctor Profile Photo (Cloudinary Url)*
                      </label>
                      <input
                        type="text"
                        id="doctorProfilePhoto"
                        className={
                          errors.doctorProfilePhoto
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Doctor Profile Photo(Cloudinary Url)"
                        {...register("doctorProfilePhoto", {
                          required: "Please enter doctor profile photo url",
                        })}
                      ></input>
                      {errors.doctorProfilePhoto && (
                        <span className="invalid-feedback">
                          {errors.doctorProfilePhoto.message}
                        </span>
                      )}
                    </div>
                    <div
                      className={`col-6 ${styles.docPortal__docDetailSec__form}`}
                    >
                      <label htmlFor="doctor">Doctor Experience*</label>
                      <input
                        type="text"
                        id="doctorExperience"
                        className={
                          errors.doctorExperience
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Doctor Experience"
                        {...register("doctorExperience", {
                          required: "Please enter doctor experience",
                        })}
                      ></input>
                      {errors.doctorExperience && (
                        <span className="invalid-feedback">
                          {errors.doctorExperience.message}
                        </span>
                      )}
                    </div>
                    <div
                      className={`col-6 ${styles.docPortal__docDetailSec__form}`}
                    >
                      <label htmlFor="doctor">Doctor Description (Info)</label>
                      <input
                        type="text"
                        id="doctorDescription"
                        className={
                          errors.doctorDescription
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Doctor Description(Info)"
                        {...register("doctorDescription")}
                      ></input>
                      {errors.doctorDescription && (
                        <span className="invalid-feedback mb-3">
                          {errors.doctorDescription.message}
                        </span>
                      )}
                    </div>
                    <div
                      className={`col-6 ${styles.docPortal__docDetailSec__form}`}
                    >
                      <label htmlFor="doctor">Doctor Assistant Name</label>
                      <input
                        type="text"
                        id="doctorAssistantName"
                        className={
                          errors.doctorAssistantName
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Doctor Assisstant Name"
                        {...register("doctorAssistantName")}
                      ></input>
                      {errors.doctorAssistantName && (
                        <span className="invalid-feedback mb-3">
                          {errors.doctorAssistantName.message}
                        </span>
                      )}
                    </div>
                    <div
                      className={`col-6 ${styles.docPortal__docDetailSec__form}`}
                    >
                      <label htmlFor="doctor">Doctor Assistant Number</label>
                      <input
                        type="text"
                        id="doctorAssistantNumber"
                        inputMode="numeric"
                        maxLength="10"
                        className={
                          errors.doctorAssistantNumber
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Doctor Assisstant Number"
                        {...register("doctorAssistantNumber", {
                          pattern: {
                            value:
                              /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/, // eslint-disable-line no-useless-escape
                            message: "Please enter valid mobile number",
                          },
                        })}
                      ></input>
                      {errors.doctorAssistantNumber && (
                        <span className="invalid-feedback mb-3">
                          {errors.doctorAssistantNumber.message}
                        </span>
                      )}
                    </div>
                    <div
                      className={`col-6 ${styles.docPortal__docDetailSec__form}`}
                    >
                      <label htmlFor="doctor">Doctor Clinic Address</label>
                      <input
                        type="text"
                        id="address"
                        className={
                          errors.address
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Address Line"
                        {...register("address")}
                      ></input>
                      {errors.address && (
                        <span className="invalid-feedback mb-3">
                          {errors.address.message}
                        </span>
                      )}
                    </div>
                    <div
                      className={`col-6 ${styles.docPortal__docDetailSec__form}`}
                    >
                      <label htmlFor="doctor">State</label>
                      <input
                        type="text"
                        id="state"
                        className={
                          errors.state
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="State"
                        {...register("state")}
                      ></input>
                      {errors.state && (
                        <span className="invalid-feedback mb-3">
                          {errors.state.message}
                        </span>
                      )}
                    </div>
                    <div
                      className={`col-6 ${styles.docPortal__docDetailSec__form}`}
                    >
                      <label htmlFor="doctor">City</label>
                      <input
                        type="text"
                        id="city"
                        className={
                          errors.city
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="City"
                        {...register("city")}
                      ></input>
                      {errors.city && (
                        <span className="invalid-feedback mb-3">
                          {errors.city.message}
                        </span>
                      )}
                    </div>
                    <div
                      className={`col-6 ${styles.docPortal__docDetailSec__form}`}
                    >
                      <label htmlFor="doctor">Pincode</label>
                      <input
                        type="text"
                        id="pincode"
                        className={
                          errors.pincode
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        inputMode="numeric"
                        maxLength="6"
                        placeholder="Pincode"
                        {...register("pincode", {
                          pattern: {
                            value: /^[1-9][0-9]{5}$/, // eslint-disable-line no-useless-escape
                            message: "Please enter valid pincode",
                          },
                          maxLength: {
                            value: 6,
                            message: "Please enter valid pincode",
                          },
                        })}
                      ></input>
                      {errors.pincode && (
                        <span className="invalid-feedback mb-3">
                          {errors.pincode.message}
                        </span>
                      )}
                    </div>
                    {props.serverError ? (
                      <div>
                        <p className="mt-4 text-danger text-center">
                          {props.serverError}
                        </p>
                      </div>
                    ) : null}
                    <div
                      className={`col-12 text-center ${styles.docPortal__docDetailSec__formCta}`}
                    >
                      <button
                        onClick={() => {
                          if (doctorSpecialisation.length === 0)
                            setShowDoctorSpecialisationError("block");
                        }}
                        type="submit"
                      >
                        Submit Details
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DoctorPortal;
