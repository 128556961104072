import { apiCalls } from "./apiCalls";

// config
import config from "../config";

// function -> to get doctor details based on email id
export const doValidateDoctorEmailId = async value => {
  const url =
    config.DX_API_BASE_URL + "/api/doctor/getDoctorByEmail?email=" + value;

  try {
    const result = await apiCalls("get", url);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error("Email Id Doctor Referral, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

// function -> to create the referral/lead by the doctor
export const doValidateLeadCreatedByDoctor = async data => {
  const url = config.DX_API_BASE_URL + "/api/doctor/createLead";

  try {
    const result = await apiCalls("post", url, data);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error(
        "Doctor referral lead creation, server err:",
        error.message
      );
      throw new Error(error.message);
    }
  }
};

// function -> to get referral leads of a particular doctor
export const doValidateReferralDetailsDoctor = async value => {
  const url = config.DX_API_BASE_URL + "/api/doctor/getLeads?doctorId=" + value;

  try {
    const result = await apiCalls("get", url);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error(
        "Referral Details Doctor Referral, server err:",
        error.message
      );
      throw new Error(error.message);
    }
  }
};

// ++++++++++++++++++++ DOCTOR LANDING PAGE API'S ++++++++++++++++++++++++++

// function to get doctor details based on twin doctor id
export const doValidateDoctorTwinId = async value => {
  const url =
    config.DX_API_BASE_URL + "/api/doctor/getDoctorByIcapId/" + value;

  try {
    const result = await apiCalls("get", url);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error("Email Id Doctor Referral, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

// To save the doctor details in dx backend i.e DoctorsLandingPage table
export const doSaveDoctorDetails = async data => {
  const url = config.DX_API_BASE_URL + "/api/doctor/create/doctorLandingPage";

  try {
    const result = await apiCalls("post", url, data);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error(
        "Doctor referral lead creation, server err:",
        error.message
      );
      throw new Error(error.message);
    }
  }
};

// To get all the doctors list with details present in for doctor landing page
export const getAllDoctorsListForDoctorLp = async () => {
  const url =
    config.DX_API_BASE_URL + "/api/doctor/list/doctors";

  try {
    const result = await apiCalls("get", url);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error("Email Id Doctor Referral, server err:", error.message);
      throw new Error(error.message);
    }
  }
}

// ++++++++++++++++++++++++++++++++++++ DOCTOR CONSULT ++++++++++++++++++++++++++++++++++++

// To save user details for doctor consult in dx-backend in doctor consult table
export const createUserDetailDoctorConsult = async (data) => {
  const url = config.DX_API_BASE_URL + "/api/doctor/consultcreateuserdata";

  try {
    const result = await apiCalls("post", url, data);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error(
        "Doctor consult user data, server err:",
        error.message
      );
      throw new Error(error.message);
    }
  }
}
