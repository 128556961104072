import React from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

// css
import * as styles from "./toast.module.css";

const propTypes = {
  position: PropTypes.string,
  show: PropTypes.bool,
  delay: PropTypes.number,
  autohide: PropTypes.bool,
  text: PropTypes.string,
};

const defaultProps = {
  position: "top-center",
  show: false,
  delay: 5000,
  autohide: true,
  text: "Welcome to TwinHealth..!!!",
};

const SuccessToast = props => {
  return (
    <ToastContainer className="p-3 mt-5" position={props.position}>
      <Toast
        className={styles.successToastSec}
        show={props.showSuccessToast}
        delay={props.delay}
        autohide={props.autohide}
        onClose={props.onClose}
      >
        <Toast.Body>
          <div className={`row d-flex`}>
            <div className={`col-2 ${styles.toastSec__bodyImg}`}>
              <StaticImage
                src="../../images/tick_alert_new.png"
                alt="tick_icon"
                className="img-fluid"
                placeholder="blurred"
                quality={90}
                width={40}
                height={40}
              />
            </div>
            <div className={`col-10 ${styles.toastSec__bodypara}`}>
              <p>{props.text}</p>
            </div>
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

SuccessToast.propTypes = propTypes;
SuccessToast.defaultProps = defaultProps;

export default SuccessToast;

// How To Use:-

// const [showSuccessToast, setShowSuccessToast] = useState(false);

/* <SuccessToast
  position="top-center"
  showSuccessToast={showSuccessToast}
  delay={5000}
  autohide={true}
  text="You have successfully referred the member."
/> */

// Position Values can be
// position={'top-start','top-center','top-end','middle-start','middle-center','middle-end','bottom-start','bottom-center','bottom-end',}
